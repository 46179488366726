import React, { useCallback } from 'react';
import { Upload, Image, FileType } from 'lucide-react';

interface FileDropZoneProps {
  onFilesDrop: (files: FileList) => void;
  isProcessing: boolean;
}

const FileDropZone: React.FC<FileDropZoneProps> = ({ onFilesDrop, isProcessing }) => {
  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!isProcessing && e.dataTransfer.files?.length > 0) {
      onFilesDrop(e.dataTransfer.files);
    }
  }, [onFilesDrop, isProcessing]);

  const handleFileInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isProcessing && e.target.files?.length > 0) {
      onFilesDrop(e.target.files);
      e.target.value = '';
    }
  }, [onFilesDrop, isProcessing]);

  return (
    <div className="relative group">
      <div className="absolute -inset-0.5 gradient-bg rounded-xl blur opacity-30 group-hover:opacity-100 transition duration-500" />
      <div className={`relative glass-panel rounded-xl transition-all duration-300 ${
        isProcessing ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-white/10'
      }`}>
        <input
          type="file"
          id="file-input"
          className="hidden"
          accept="image/*,.pdf"
          onChange={handleFileInput}
          multiple
          disabled={isProcessing}
        />
        
        <label
          htmlFor="file-input"
          className={`block p-8 text-center ${isProcessing ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div className="relative animate-float">
            <div className="absolute -inset-4 gradient-bg rounded-full blur-xl opacity-30" />
            <div className="relative w-20 h-20 mx-auto rounded-full gradient-border p-0.5">
              <div className="relative w-full h-full rounded-full glass-panel flex items-center justify-center">
                {isProcessing ? (
                  <Upload className="w-10 h-10 text-[#4ECDC4] animate-bounce" />
                ) : (
                  <div className="flex gap-2">
                    <Image className="w-8 h-8 text-[#4ECDC4]" />
                    <FileType className="w-8 h-8 text-[#4ECDC4]" />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="mt-6 space-y-2">
            <p className="text-xl font-semibold gradient-text neon-glow">
              {isProcessing ? 'Processing...' : 'Drop your files here'}
            </p>
            <p className="text-sm text-[#4ECDC4]/80">
              {isProcessing ? 'Please wait' : 'Supports PDF, WebP, JPEG, PNG, AVIF, and TIFF input'}
            </p>
            <div className="h-px w-48 mx-auto bg-gradient-to-r from-transparent via-[#4ECDC4]/30 to-transparent" />
            <p className="text-xs text-[#4ECDC4]/60">
              Convert to WebP, JPEG, PNG, or AVIF
            </p>
          </div>
        </label>
      </div>
    </div>
  );
};

export default FileDropZone;
export const SUPPORTED_FORMATS = {
  input: ['webp', 'jpg', 'jpeg', 'tiff', 'tif', 'png', 'pdf', 'avif'],
  output: ['png', 'jpeg', 'webp', 'avif'] as const
};

export const FORMAT_CONFIG = {
  jpeg: {
    mimeType: 'image/jpeg',
    quality: 0.92,
    label: 'JPEG'
  },
  webp: {
    mimeType: 'image/webp',
    quality: 0.92,
    label: 'WebP'
  },
  png: {
    mimeType: 'image/png',
    quality: undefined,
    label: 'PNG'
  },
  avif: {
    mimeType: 'image/avif',
    quality: 0.85,
    label: 'AVIF'
  }
} as const;

export const PDF_CONVERSION_SCALE = 2.0;
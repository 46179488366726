import React from 'react';
import type { OutputFormat } from '../utils/imageConverter';

interface FormatSelectorProps {
  selectedFormat: OutputFormat;
  onFormatChange: (format: OutputFormat) => void;
  disabled?: boolean;
}

const formats: { value: OutputFormat; label: string }[] = [
  { value: 'png', label: 'PNG' },
  { value: 'jpeg', label: 'JPEG' },
  { value: 'webp', label: 'WebP' },
  { value: 'avif', label: 'AVIF' }
];

const FormatSelector: React.FC<FormatSelectorProps> = ({
  selectedFormat,
  onFormatChange,
  disabled
}) => {
  return (
    <div className="flex justify-center gap-2 p-2 glass-panel rounded-xl">
      {formats.map(({ value, label }) => (
        <button
          key={value}
          onClick={() => onFormatChange(value)}
          disabled={disabled}
          className={`relative px-6 py-2.5 rounded-lg font-medium transition-all duration-300 hover-scale ${
            selectedFormat === value
              ? 'text-white gradient-border'
              : 'text-gray-400 hover:text-white'
          } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <span className="relative z-10">{label}</span>
          {selectedFormat === value && (
            <div className="absolute inset-0 glass-panel rounded-lg -z-10" />
          )}
        </button>
      ))}
    </div>
  );
};

export default FormatSelector;